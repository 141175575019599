import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop";
import CheckLocation from "./Components/Location/CheckLocation.jsx";

// ----- Home -----
const Home = lazy(() => import("./PagesPT/Home"));
const HomeEN = lazy(() => import("./PagesEN/Home"));
// ----------

// ----- About Pages -----
const OurStoryPage = lazy(() => import("./PagesPT/About/OurStoryPage"));
const OurStoryPageEN = lazy(() => import("./PagesEN/About/OurStoryPage"));
const OurResponsibility = lazy(() => import("./PagesPT/About/OurResponsibility"));
const OurResponsibilityEN = lazy(() => import("./PagesEN/About/OurResponsibility"));
const OurTeamPage = lazy(() => import("./PagesPT/About/OurTeamPage"));
const OurTeamPageEN = lazy(() => import("./PagesEN/About/OurTeamPage"));
const OurMisson = lazy(() => import("./PagesPT/About/OurMisson"));
const OurMissonEN = lazy(() => import("./PagesEN/About/OurMisson"));
// ----------

// ----- Contact Pages -----
const ContactUs = lazy(() => import("./PagesPT/Contact/ContactUs"));
const ContactUsEN = lazy(() => import("./PagesEN/Contact/ContactUs"));
// ----------

// ----- Additional Pages -----
const NotFoundPage = lazy(() => import("./PagesPT/AdditionalPages/404"));
const NotFoundPageEN = lazy(() => import("./PagesEN/AdditionalPages/404"));
const SomethingWrong = lazy(() => import("./PagesPT/AdditionalPages/303"));
const SomethingWrongEN = lazy(() => import("./PagesEN/AdditionalPages/303"));
const Privacy = lazy(() => import("./PagesPT/AdditionalPages/Privacy"));
const PrivacyEN = lazy(() => import("./PagesEN/AdditionalPages/Privacy"));
const Cookies = lazy(() => import("./PagesPT/AdditionalPages/Cookies"));
const CookiesEN = lazy(() => import("./PagesEN/AdditionalPages/Cookies"));
// ----------

// ----- Brands -----
const NVentHoffman = lazy(() => import("./PagesPT/Brands/nvent-hoffman"));
const NVentHoffmanEN = lazy(() => import("./PagesEN/Brands/nvent-hoffman"));
const Datasensing = lazy(() => import("./PagesPT/Brands/datasensing"));
const DatasensingEN = lazy(() => import("./PagesEN/Brands/datasensing"));
const Patlite = lazy(() => import("./PagesPT/Brands/patlite"));
const PatliteEN = lazy(() => import("./PagesEN/Brands/patlite"));
const PhoenixContact = lazy(() => import("./PagesPT/Brands/phoenix-contact"));
const PhoenixContactEN = lazy(() => import("./PagesEN/Brands/phoenix-contact"));
const RockwellAutomation = lazy(() => import("./PagesPT/Brands/rockwell-automation"));
const RockwellAutomationEN = lazy(() => import("./PagesEN/Brands/rockwell-automation"));
const Secomea = lazy(() => import("./PagesPT/Brands/secomea"));
const SecomeaEN = lazy(() => import("./PagesEN/Brands/secomea"));
const Siemens = lazy(() => import("./PagesPT/Brands/siemens"));
const SiemensEN = lazy(() => import("./PagesEN/Brands/siemens"));
const Gewiss = lazy(() => import("./PagesPT/Brands/gewiss"));
const GewissEN = lazy(() => import("./PagesEN/Brands/gewiss"));
const Xecro = lazy(() => import("./PagesPT/Brands/xecro"));
const XecroEN = lazy(() => import("./PagesEN/Brands/xecro"));
// ----------

// ----- Support -----
const TechSupport = lazy(() => import("./PagesPT/Support/TechSupport"));
const TechSupportEN = lazy(() => import("./PagesEN/Support/TechSupport"));
const Consulting = lazy(() => import("./PagesPT/Support/Consulting"));
const ConsultingEN = lazy(() => import("./PagesEN/Support/Consulting"));
const Training = lazy(() => import("./PagesPT/Support/Training"));
const TrainingEN = lazy(() => import("./PagesEN/Support/Training"));
const Questions = lazy(() => import("./PagesPT/Support/Forms/Questions"));
const QuestionsEN = lazy(() => import("./PagesEN/Support/Forms/Questions"));
const RepairsWarranties = lazy(() => import("./PagesPT/Support/Forms/RepairsWarranties"));
const RepairsWarrantiesEN = lazy(() => import("./PagesEN/Support/Forms/RepairsWarranties"));
// ----------

// ----- Products -----
const AutomationControl = lazy(() => import("./PagesPT/Products/AutomationControl"));
const AutomationControlEN = lazy(() => import("./PagesEN/Products/AutomationControl"));
const ProcessInstrumentation = lazy(() => import("./PagesPT/Products/ProcessInstrumentation"));
const ProcessInstrumentationEN = lazy(() => import("./PagesEN/Products/ProcessInstrumentation"));
const DrivesActuators = lazy(() => import("./PagesPT/Products/DrivesActuators"));
const DrivesActuatorsEN = lazy(() => import("./PagesEN/Products/DrivesActuators"));
const Detection = lazy(() => import("./PagesPT/Products/Detection"));
const DetectionEN = lazy(() => import("./PagesEN/Products/Detection"));
const ArtificialVision = lazy(() => import("./PagesPT/Products/ArtificialVision"));
const ArtificialVisionEN = lazy(() => import("./PagesEN/Products/ArtificialVision"));
const Security = lazy(() => import("./PagesPT/Products/Security"));
const SecurityEN = lazy(() => import("./PagesEN/Products/Security"));
const RemoteAccess = lazy(() => import("./PagesPT/Products/RemoteAccess"));
const RemoteAccessEN = lazy(() => import("./PagesEN/Products/RemoteAccess"));
const Distribution = lazy(() => import("./PagesPT/Products/Distribution"));
const DistributionEN = lazy(() => import("./PagesEN/Products/Distribution"));
const ThermalManagementCabinets = lazy(() => import("./PagesPT/Products/ThermalManagementCabinets"));
const ThermalManagementCabinetsEN = lazy(() => import("./PagesEN/Products/ThermalManagementCabinets"));
const Signage = lazy(() => import("./PagesPT/Products/Signage"));
const SignageEN = lazy(() => import("./PagesEN/Products/Signage"));
// ----------

function App() {
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [customModal, setCustomModal] = useState({ el: null, isOpen: false });
    const location = useLocation();

    useEffect(() => {
        window.addEventListener("load", retina(document.querySelectorAll("img")));
    }, []);

    useEffect(() => {
        setTimeout(() => {
            import("./Functions/Utilities").then((module) => {
                module.SetHeaderMenuPos();
                module.setDocumentFullHeight();
            });
        }, 1000);
    }, [location]);

    useEffect(() => {
        if (isModalOpen === true) {
            document.querySelector("body").classList.add("overflow-hidden");
        } else {
            document.querySelector("body").classList.remove("overflow-hidden");
        }
    }, [isModalOpen]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant",
        });
        setFooterHeight(0);
        setCustomModal({
            ...customModal,
            el: null,
            isOpen: false,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        (function (I, L, T, i, c, k, s) {
            if (I.iticks) return;
            I.iticks = { host: c, settings: s, clientId: k, cdn: L, queue: [] };
            var h = T.head || T.documentElement;
            var e = T.createElement(i);
            e.async = true;
            e.src = (L || c) + '/client/inject-v2.min.js';
            h.insertBefore(e, h.firstChild);
            I.iticks.call = function (a, b) {
                I.iticks.queue.push([a, b]);
            };
        })(window, 'https://cdn-v1.intelliticks.com/prod/common', document, 'script', 'https://app.intelliticks.com', 'xqiRhHtoapB7ZYYDk_c', {});
    }, []);

    return (
        <GlobalContext.Provider
            value={{ headerHeight, setHeaderHeight, footerHeight, setFooterHeight, isModalOpen, setIsModalOpen, customModal, setCustomModal }}>
            <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
                {
                    <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
                        <ScrollToTopButton />

                        <AnimatePresence exitBeforeEnter>
                            <Suspense fallback={<></>}>
                                <Routes>
                                    <Route path="/" element={<CheckLocation />} />
                                    <Route path="/pt/" element={<Home />} />
                                    <Route path="/en/" element={<HomeEN />} />

                                    {/*About Pages */}
                                    <Route path="/pt/our-story" element={ <OurStoryPage style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/our-story" element={ <OurStoryPageEN style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/pt/our-responsibility" element={ <OurResponsibility style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/en/our-responsibility" element={<OurResponsibilityEN style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/pt/our-team" element={ <OurTeamPage style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/our-team" element={ <OurTeamPageEN style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/pt/our-misson" element={<OurMisson style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/en/our-misson" element={<OurMissonEN style={{ "--base-color": "#85260E" }} />} />

                                    {/* Contact Pages */}
                                    <Route path="/pt/contacts" element={ <ContactUs style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/contacts" element={ <ContactUsEN style={{ "--base-color": "#F7F7F7" }} />} />

                                    {/* Additional Pages */}
                                    <Route path="/pt/404" element={ <NotFoundPage style={{ "--base-color": "#F7F7F7" }} />}/>
                                    <Route path="/en/404" element={ <NotFoundPageEN style={{ "--base-color": "#F7F7F7" }} />}/>
                                    <Route path="/pt/303" element={ <SomethingWrong style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/303" element={ <SomethingWrongEN style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/pt/*" element={ <NotFoundPage style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/*" element={ <NotFoundPageEN style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/pt/privacy" element={ <Privacy style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/privacy" element={ <PrivacyEN style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/pt/cookies" element={ <Cookies style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/cookies" element={ <CookiesEN style={{ "--base-color": "#F7F7F7" }} />} />

                                    {/* Brands */}
                                    <Route path="/pt/rockwell-automation" element={ <RockwellAutomation style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/en/rockwell-automation" element={ <RockwellAutomationEN style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/pt/siemens" element={ <Siemens style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/en/siemens" element={ <SiemensEN style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/pt/phoenix-contact" element={ <PhoenixContact style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/en/phoenix-contact" element={ <PhoenixContactEN style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/pt/nvent-hoffman" element={ <NVentHoffman style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/en/nvent-hoffman" element={ <NVentHoffmanEN style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/pt/datasensing" element={ <Datasensing style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/en/datasensing" element={ <DatasensingEN style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/pt/gewiss" element={ <Gewiss style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/en/gewiss" element={ <GewissEN style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/pt/patlite" element={ <Patlite style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/en/patlite" element={ <PatliteEN style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/pt/secomea" element={ <Secomea style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/en/secomea" element={ <SecomeaEN style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/pt/xecro" element={ <Xecro style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/en/xecro" element={ <XecroEN style={{ "--base-color": "#85260E" }} />} />

                                    {/* Support */}
                                    <Route path="/pt/tech-support" element={ <TechSupport style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/tech-support" element={ <TechSupportEN style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/pt/consulting" element={ <Consulting style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/en/consulting" element={ <ConsultingEN style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/pt/training" element={ <Training style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/en/training" element={ <TrainingEN style={{ "--base-color": "#85260E" }} />} />
                                    <Route path="/pt/questions" element={ <Questions style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/questions" element={ <QuestionsEN style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/pt/repairs-warranties" element={ <RepairsWarranties style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/repairs-warranties" element={ <RepairsWarrantiesEN style={{ "--base-color": "#F7F7F7" }} />} />
                    
                                    {/* Products */}
                                    <Route path="/pt/automation-control" element={ <AutomationControl style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/automation-control" element={ <AutomationControlEN style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/pt/process-instrumentation" element={ <ProcessInstrumentation style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/process-instrumentation" element={ <ProcessInstrumentationEN style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/pt/drives-actuators" element={ <DrivesActuators style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/drives-actuators" element={ <DrivesActuatorsEN style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/pt/detection" element={ <Detection style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/detection" element={ <DetectionEN style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/pt/artificial-vision" element={ <ArtificialVision style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/artificial-vision" element={ <ArtificialVisionEN style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/pt/security" element={ <Security style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/security" element={ <SecurityEN style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/pt/remote-access" element={ <RemoteAccess style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/remote-access" element={ <RemoteAccessEN style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/pt/distribution" element={ <Distribution style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/distribution" element={ <DistributionEN style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/pt/thermal-management-cabinets" element={ <ThermalManagementCabinets style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/thermal-management-cabinets" element={ <ThermalManagementCabinetsEN style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/pt/signage" element={ <Signage style={{ "--base-color": "#F7F7F7" }} />} />
                                    <Route path="/en/signage" element={ <SignageEN style={{ "--base-color": "#F7F7F7" }} />} />
                                </Routes>
                            </Suspense>
                        </AnimatePresence>
                    </main>
                }
            </div>
        </GlobalContext.Provider>
    );
}

export default App;