import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CheckLocation = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const fetchLocation = async () => {
            try {
                const response = await axios.get('https://ipinfo.io?token=5a782e7a82cb06');
                const country = response.data.country;

                if (country === 'PT') {
                    navigate('/pt/');
                } else {
                    navigate('/en/');
                }
            } catch (error) {
                console.error('Error fetching location', error);
                navigate('/pt/');
            }
        };

        fetchLocation();
    }, [navigate]);

    return <div></div>;
};

export default CheckLocation;
